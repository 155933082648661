import React from 'react'
import { graphql } from 'gatsby'
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet'

import Layout from '../components/layout'
import PageHeader from '../components/page-header'
import SEO from '../components/seo'
import PageSubtitle from '../components/page-subtitle'

const MapMarker = ({ mapLink }) => (
  <a href={mapLink} target="_blank" rel="noopener noreferrer">
    Click for fullscreen map
  </a>
)

const AddressDetails = ({ icon, title, detail }) => {
  return (
    <div className="text-white mb-4">
      <div>
        <i className={`${icon} mb-2`}></i>
      </div>
      <h4 className="mb-2">{title}</h4>
      {Array.isArray(detail) ? (
        detail.map((det) => (
          <p className="mb-0" key={det}>
            {det}
          </p>
        ))
      ) : (
        <p>{detail}</p>
      )}
    </div>
  )
}
export default (props) => {
  const {
    pageTitle = 'Contact us',
    subtitle: { subtitle },
    address: { address },
    email,
    telephone,
    location: { lat, lon },
    mapLink = 'https://maps.google.com',
    mapZoomLevel = 18,
  } = props.data.allContentfulPageContactUsModal.nodes[0]

  const mapPosition = [lat, lon]

  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <section className="container mt-5 mb-4 pb-5">
          <PageSubtitle subtitle={subtitle} />
          <div className="row mt-5">
            <div className="col-md-3 mb-5">
              <div className="contact-box h-100 px-3 py-4">
                <AddressDetails icon="fas fa-map-marked-alt" title="Address" detail={address} />
                <AddressDetails icon="fas fa-envelope" title="Email" detail={email} />
                <AddressDetails icon="fas fa-phone-alt" title="Phone" detail={telephone} />
              </div>
            </div>
            <div className="col-md-9 mb-5">
              {typeof window !== `undefined` && (
                <LeafletMap
                  center={mapPosition}
                  zoom={mapZoomLevel}
                  attributionControl={true}
                  zoomControl={true}
                  doubleClickZoom={false}
                  scrollWheelZoom={false}
                  keyboard={false}
                  dragging={true}
                  animate={true}
                  easeLinearity={0.35}>
                  <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                  <Marker position={mapPosition}>
                    <Popup>
                      <MapMarker mapLink={mapLink} />
                    </Popup>
                  </Marker>
                </LeafletMap>
              )}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulPageContactUsModal {
      nodes {
        pageTitle
        subtitle {
          subtitle
        }
        address {
          address
        }
        email
        telephone
        location {
          lat
          lon
        }
        mapLink
        mapZoomLevel
      }
    }
  }
`
